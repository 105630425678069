<script setup lang="ts">
import {
	type CasinoCtaBlockFragment,
	PublicationsDocument,
	type PublicationsQuery,
} from '~/generated/operations'

const props = defineProps<{
	block: CasinoCtaBlockFragment
}>()

// load 10 random articles for casino
const { data } = useLazyAsyncQuery<PublicationsQuery>(PublicationsDocument, {
	limit: 20,
	orderBy: 'random()',
})

const showCasino = ref(false)
const el = ref()
const documentEl = ref()
const { isMobile } = useDevice()
const buttonEl = ref<HTMLButtonElement>()
const { width: buttonWidth, height: buttonHeight } = useElementSize(buttonEl)
const { width: containerWidth, height: containerHeight } = useElementSize(el)
let dX = 1
let dY = 1
let x = 0
let y = 0
const { pause, resume } = useRafFn(() => {
	if (!buttonEl.value) return

	buttonEl.value.style.left = `${x}px`
	buttonEl.value.style.top = `${y}px`

	x += dX
	y += dY

	if (x + buttonWidth.value > containerWidth.value - 1 || x < 0) dX *= -1
	if (y + buttonHeight.value > containerHeight.value - 1 || y < 0) dY *= -1
})

// pause/resume requestAnimationFrame when container is (in)visible
const isVisible = useElementVisibility(el)
watch(isVisible, (visible) => {
	if (visible) {
		resume()
	} else {
		pause()
	}
})


onMounted(() => {
	documentEl.value = document.documentElement

	// random initial position
	x = Math.random() * (containerWidth.value - buttonWidth.value)
	y = Math.random() * (containerHeight.value - buttonHeight.value)

	// random initial direction
	if (Math.random() > 0.5) dX *= -1
	if (Math.random() > 0.5) dY *= -1
})

const isLocked = useScrollLock(documentEl)
watch([showCasino], () => {
	isLocked.value = showCasino.value
})

const openCasino = async() => {
	showCasino.value = true
	await nextTick();          // waits until the DOM is reset and ready

	
	setTimeout(() => {
		pause()
	}, 1)
	setTimeout(() => {
	document.querySelector(".start-rad-opnieuw").focus();
	}, 1000)

}

const closeCasino = () => {
	showCasino.value = false
	resume()
}
</script>
<template>
	<div class="casino-cta" ref="el">
		<div class="text">{{ block.heading }}</div>
		<div class="button-container" ref="buttonEl">
			<Button
				:size="isMobile ? 'medium' : 'casino'"
				:type="'button'"
				color="green"
				@mouseenter="pause"
				@mouseleave="resume"
				@click="openCasino"
				>{{ block.ctaText }}</Button
			>
		</div>

		<Teleport to="body">
			<Casino
				ref="casino"
				class="duration-[.3s]"
				v-motion-fade
				:publications="data.entries"
				v-if="showCasino"
				@close="closeCasino"
			/>
		</Teleport>
	</div>
</template>

<style scoped lang="postcss">
.casino-cta {
	@apply relative h-[240px] sm:h-[355px] w-full bg-black overflow-hidden;

	.text {
		@apply h-full w-full text-white flex justify-center items-center px-7 text-[40px] sm:text-[55px] font-semibold tracking-tight max-w-[300px] md:max-w-[630px] leading-none text-center mx-auto;
	}

	.button-container {
		@apply absolute top-0 left-0;
		transform: translateZ(0);
	}
}
</style>
